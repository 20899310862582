import React from "react"

import { Link } from "gatsby"

import ImageComponent from "../../pageSections/imageComponent"
import CardBadge from "../cardBadge"
import ShareButton from "../shareButton"

const BlogCard = props => {
  const { data, openVideoModal,setMetaImage } = props
  
  

  const defaultImage = "https://via.placeholder.com/348x233"

  const playVideo = () => {
    if (openVideoModal && data.video) {
      openVideoModal(data.video)
    }
  }

 
  return (
    <>
      <div className="card blog_card">
        <div className="img_video_wrapper">
          {/* <ImageComponent
                        content={data}
                    /> */}
          <img
            className="lazyload"
            src={data?.headerImage || defaultImage}
            alt={data?.title || ""}
          />
          {data?.video !== null && (
            <button
              onClick={playVideo}
              data-target="#modal_video"
              aria-label="Play Video"
            >
              <img src="/assets/images/icons/video_icon_white.svg" alt="" />
            </button>
          )}
        </div>
        <div className="card-body">
          <div className="badge_wrap">
            {data.category && <CardBadge data={data.category} />}
            <ShareButton url={data.path} title={data.title ? data.title : "Hinduja Hospital"} setMetaImage={setMetaImage} image={data?.headerImage || defaultImage} />
          </div>
          <h3>{data.title || ""}</h3>
          <div className="row mb-2">
            <div className="col-md-7 col-7">
              <div className="by_date">
                <a href={data?.profile_url || '/'}>{data.author || ""}</a> <br />
                <span>{data.date || ""}</span>
              </div>
            </div>
            <div className="col-md-5 col-5">
              <div className="star_wrap">
                {/* <div className="starrating">
                                    <input type="number" name="inputName" id="rating1" className="rating" />      
                                </div> */}
                {/* <div className="comments_no">
                                    <a href="#"> 50 Comments </a>
                                </div> */}
              </div>
            </div>
          </div>
          <p>{data.summary || ""}</p>
        </div>
        <div className="card-link">
          {data.path && (
            <Link
              to={data.path}
              className="btn-link"
              aria-label={data.title || ""}
            >
              Read More
            </Link>
          )}
        </div>
      </div>
    </>
  )
}

export default BlogCard
